import React from "react";
import Layout from "../components/Layout";
import SocialLinks from "../components/socialLinks";
import SeO from "../components/SEO";

function contact() {
  return (
    <Layout>
      <SeO title="Contact" />
      <section className="contact">
        <div className="contactContent">
          <h2 className="contact-title">Contact me</h2>
          <div className="underline"></div>
          <p className="contact-paragraph">
            Feel free to get in touch with me with anything related to this blog
            or you can just say hi 👋
          </p>
          <p className="contact-paragraph"> I'll get back to you shortly ! </p>
          <p className="contact-paragraph">
            In case you want to connect you can check below where you can find
            me or if you prefer just drop me an email.
          </p>
          <SocialLinks styleClass="banner-icons" />
        </div>
        <div className="contactForm">
          <form
            method="POST"
            action="/success"
            name="contact"
            data-netlify="true"
          >
            <input
              name="name"
              type="text"
              className="contact-input"
              placeholder="Name"
            />
            <input
              name="email"
              type="text"
              className="contact-input"
              placeholder="Email"
            />
            <textarea
              name="Message"
              className="contact-input"
              placeholder="Message"
            ></textarea>
            <input type="submit" value="SUBMIT" />
          </form>
        </div>
      </section>
    </Layout>
  );
}

export default contact;
